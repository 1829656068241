@function reduce ($percent) {
  @return rgba(mix(#282828, #fff), $percent / 100); }

$link-color: #68f;

@import "anchor";

body > header, article, body > footer {
  padding: 1.5em calc(27.5% - 5rem); }

article, body > footer {
  border-top: 1px solid reduce(8); }



article {
  background: reduce(2); }

body > footer {
  background: reduce(5);
  color: reduce(100); }


